/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import footerLogo from "../assets/images/logo/footer-logo.svg";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

const Footer = () => {
  const updatedDate = new Date().getFullYear();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  return (
    <footer className="bg-black bg-[url('../images/all-img/footer-bg-1.png')] bg-cover bg-center bg-no-repeat">
      <div className="section-padding container">
        <div className="grid grid-cols-1 gap-7 md:grid-cols-2 lg:grid-cols-3">
          <div className="single-footer">
            <div className="lg:max-w-[270px]">
              <a href="#" className="mb-10 block">
                <img src={footerLogo} alt="" />
              </a>
              <p>
                Indian Institute of Career Development empowers individuals with
                career-focused training, practical skills, and industry-aligned
                programs for success.
              </p>
              <ul className="flex space-x-4 pt-8">
                <li>
                  <a
                    href="#"
                    className="flex h-12 w-12 flex-col items-center justify-center rounded bg-white bg-opacity-[0.08] text-2xl text-white
                  transition hover:bg-primary hover:text-white"
                  >
                    <iconify-icon icon="bxl:facebook"></iconify-icon>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex h-12 w-12 flex-col items-center justify-center rounded bg-white bg-opacity-[0.08] text-2xl text-white
                  transition hover:bg-primary hover:text-white"
                  >
                    <iconify-icon icon="bxl:twitter"></iconify-icon>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex h-12 w-12 flex-col items-center justify-center rounded bg-white bg-opacity-[0.08] text-2xl text-white
                  transition hover:bg-primary hover:text-white"
                  >
                    <iconify-icon icon="bxl:linkedin"></iconify-icon>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex h-12 w-12 flex-col items-center justify-center rounded bg-white bg-opacity-[0.08] text-2xl text-white
                  transition hover:bg-primary hover:text-white"
                  >
                    <iconify-icon icon="bxl:instagram"></iconify-icon>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="single-footer">
            <div className="flex space-x-[80px]">
              <div className="flex-1 lg:flex-none relative left-1/2 -translate-x-1/2">
                <h4 className="mb-8 text-footer font-bold text-white">Links</h4>
                <ul className="list-item space-y-5">
                  <li className="cursor-pointer">
                    <a onClick={() => navigate("/")}>Home</a>
                  </li>
                  <li className="cursor-pointer">
                    <a onClick={() => navigate("/about")}>About Us</a>
                  </li>
                  <li className="cursor-pointer">
                    <a onClick={() => navigate("/courses")}>Courses</a>
                  </li>
                  <li className="cursor-pointer">
                    <a onClick={() => navigate("/contacts")}>Contact Us</a>
                  </li>
                </ul>
              </div>
              {/* <div className="flex-1 lg:flex-none">
                <h4 className="mb-8 text-footer font-bold text-white">Legal</h4>
                <ul className="list-item space-y-5">
                  <li>
                    <a >Legal</a>
                  </li>
                  <li>
                    <a >Tearms of Use</a>
                  </li>
                  <li>
                    <a >Tearm & Condition</a>
                  </li>
                  <li>
                    <a >Payment Method</a>
                  </li>
                  <li>
                    <a >Privacy Policy</a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
          <div className="single-footer">
            <h4 className="mb-8 text-footer font-bold text-white">
              Stay Ahead with Career Insights
            </h4>
            <div className="mb-8">
              Join thousands of professionals receiving exclusive updates,
              expert tips, and career growth resources.
            </div>
            <div className="mb-4 flex items-center rounded-md bg-white py-[10px] pr-[10px] pl-6 shadow-e1">
              <div className="flex-none">
                <span className=" ">
                  <img src="assets/images/icon/mail.svg" alt="" />
                </span>
              </div>
              <div className="flex-1">
                <input
                  type="text"
                  placeholder="Enter your mail"
                  className="border-none focus:ring-0"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <button
              onClick={() => {
                if (email == "") {
                  message.error({
                    content: "Please Enter Email",
                    duration: 5, // Duration in seconds
                  });
                } else {
                  message.success({
                    content: "Submitted Successfully",
                    duration: 5, // Duration in seconds
                  });
                  setEmail("");
                }
              }}
              className="btn btn-primary block w-full text-center"
            >
              Email Us
            </button>
          </div>
        </div>
      </div>
      <div className="container border-t border-white border-opacity-[0.1] py-8 text-center text-base">
        &copy; Copyright {updatedDate} | v1.1.1 |{" "}
        <a href="https://riolabz.com/" target="_blank" rel="noreferrer">
          Riolabz
        </a>{" "}
        | All Rights Reserved
      </div>
    </footer>
  );
};

export default Footer;
