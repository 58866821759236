/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { courseData, courseDataInPage } from "../constant/dummyData";
import Course from "./Course";
import { Link, useNavigate } from "react-router-dom";
import StarRating from "./Pages/StarRating";
const Courses = () => {
  const navigate = useNavigate();

  return (
    <div className=" section-padding">
      <div className="container">
        <div className="text-center">
          <div className="mini-title">Popular Courses</div>
          <div className="column-title ">
            Choose Our Top <span className="shape-bg">Courses</span>
          </div>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px] pt-10">
          {courseDataInPage?.slice(0, 6).map((item, index) => (
            <Link
              className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
              to={`/course-detail/${item.url}`}
              key={`${item.id}${index}`}
            >
              <div
                // onClick={() => {
                //   localStorage.setItem("selectedCourse", item.url);
                //   selectCourse(item.url);
                // }}
                className="course-thumb h-[248px] rounded-t-[8px]  relative"
              >
                <img
                  src={item.img}
                  alt=""
                  className=" w-full h-full object-cover rounded-t-[8px]"
                />
              </div>
              <div className="course-content p-8">
                <h4 className=" text-xl mb-3 font-bold h-[60px]">
                  {item.title}
                </h4>
                <div className="flex justify-between items-center mb-3">
                  {/* <div className="text-secondary font-bold text-2xl ">
                    {item.price}
                  </div> */}
                  {/* <img src={reviewStars} className="w-[150px]" /> */}
                  <StarRating rating={4.5} />
                </div>

                {/* <div className="flex justify-between  flex-wrap space-y-1 xl:space-y-0">
                    <span className=" flex items-center space-x-2 mr-3">
                      <img src={file} alt="" />
                      <span>2 Lessons</span>
                    </span>
                    <span className=" flex items-center space-x-2 mr-3">
                      <img src={clock} alt="" />
                      <span> {item.time} </span>
                    </span>
                    <span className=" flex items-center space-x-2 ">
                      <img src={star} alt="" />
                      <span>{item.ratings}</span>
                    </span>
                  </div> */}
              </div>
            </Link>
          ))}
          {/* {courseData.map((course, index) => (
            <Link to={`/course-detail/${course.slug}`}>
              <Course course={course} key={index} index={index} />
            </Link>
          ))} */}
        </div>
        <div className="text-center lg:pt-16 pt-10">
          <a className=" btn btn-primary" onClick={() => navigate("/courses")}>
            View All Courses
          </a>
        </div>
      </div>
    </div>
  );
};

export default Courses;
