import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Form, Input, message } from "antd";

const ContactModal = ({isModalOpen, setIsModalOpen}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const naviget = useNavigate();

  const handleSubmit = (e) => {
    if(name=="" || email=="" || contact=="" || description==""){
      message.error({
        content: "Please enter all Fields",
        duration: 5, // Duration in seconds
      });
      return;
    }
    e.preventDefault();
    setLoading(true);
    const templateParams = {
      name: name,
      email: email,
      contact: contact,
      description: description,
    };
    console.log(templateParams);

    emailjs
      .send(
        "service_ns6jxe8",
        "template_oxnjbzr",
        templateParams,
        "VrrYupDuebtgZisdc"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setEmail("");
          setName("");
          setDescription("");
          naviget("/thanks");
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          message.success({
            content: "Submitted Successfully...",
            duration: 5, // Duration in seconds
          });
          setIsModalOpen(false);
          setName("")
          setEmail("")
          setContact("")
          setDescription("")
          console.log("FAILED...", err);
        }
      );
  };
  return (
    <Modal
    // title="Form Modal"
    open={isModalOpen}
    onCancel={() => setIsModalOpen(false)}
    footer={null}
  >
    <form
      onSubmit={handleSubmit}
      className=" md:grid-cols-2 grid grid-cols-1 gap-[30px] mt-6 "
    >
      <div>
        <input
          type="text"
          className=" from-control"
          placeholder="Name*"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div>
        <input
          type="email"
          className=" from-control"
          placeholder="Email*"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="md:col-span-2 col-span-1">
        <input
          className=" from-control"
          placeholder="Contact*"
          value={contact}
          onChange={(e) => setContact(e.target.value)}
        />
      </div>
      <div className="md:col-span-2 col-span-1">
        <textarea
          className=" from-control"
          placeholder="Description*"
          rows="5"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        ></textarea>
      </div>
      <button class="btn btn-primary mt-[10px]" type="submit" name="submit">
        {loading ? 'sending..' : 'Join Now'}
      </button>
    </form>
    </Modal>
  );
};

export default ContactModal;
