import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CourseDetails from "./CourseDetails";
import Header from "../../Header";
import Footer from "../../Footer";
import PageBanner from "../../PageBanner";
import { courseDetails } from "../../../constant/dummyData";

const CoursePage = ({ pageId }) => {
  const params = useParams();
  const [data, setData] = useState();
  const [relatedCourses, setRelatedCourses] = useState([]);
  useEffect(() => {
    const filteredCourse = courseDetails?.filter(
      (item) => item?.id == params.courseId
    );
    setData({ ...filteredCourse[0] });
    const related = courseDetails?.filter(
      (item) => item?.id != params.courseId
    );
    setRelatedCourses(related);
  }, [params.courseId]);
  return (
    <>
      <Header />
      <PageBanner
        title={"Course Details"}
        pageName={data?.title}
        showBreadCrumb={true}
      />
      <CourseDetails data={data} relatedCourses={relatedCourses} />
      <Footer />
    </>
  );
};

export default CoursePage;
