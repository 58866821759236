import { emptyStar, halfStar, singleStar } from "../../constant/images";

const StarRating = ({ rating, width }) => {
    // Calculate full, half, and empty stars
    const fullStars = Math.floor(rating); // Number of full stars
    const hasHalfStar = rating % 1 !== 0; // Check if there's a half star
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0); // Remaining stars are empty
  
    // Render the stars
    return (
      <div className="star-rating" style={{ display: 'flex', gap: '5px' }}>
        {/* Full stars */}
        {[...Array(fullStars)].map((_, index) => (
          <img style={{width: width}} className={`w-[${width}]`} key={`full-${index}`} src={singleStar} alt="Full Star" />
        ))}
        {/* Half star */}
        {hasHalfStar && <img style={{width: width}} className={`w-[${width}]`} src={halfStar} alt="Half Star" />}
        {/* Empty stars */}
        {[...Array(emptyStars)].map((_, index) => (
          <img style={{width: width}} className={`w-[${width}]`} key={`empty-${index}`} src={emptyStar} alt="Empty Star" />
        ))}
      </div>
    );
  };

  export default StarRating