import React from "react";
import mailIcon from "../assets/images/icon/mail.svg";
import ManOne from "../assets/images/banner/man1.svg";
import { useNavigate } from "react-router-dom";

const Banner = () => {
  const navigate = useNavigate();

  return (
    <section
      className={`xl:min-h-screen bg-[url('../images/banner/1.png')] bg-cover bg-no-repeat bg-center overflow-hidden`}
    >
      <div className="container relative">
        <div
          style={{ maxWidth: "700px" }}
          className="max-w-[570px] xl:pt-[297px] md:pt-20 md:pb-20 pt-28 pb-14 xl:pb-40 space-y-8"
        >
          <h1>
            Empowering{" "}
            <span
              style={{ color: "#011CBE" }}
              className=" text-secondary inline-block bg-[url('../images/banner/shape.svg')]  bg-no-repeat bg-bottom"
            >
              Futures
            </span>{" "}
            Through Career Guidance
          </h1>
          <div className=" plain-text text-gray leading-[30px]">
            Join the leading institute for career development and shape your
            future today!
          </div>
          <div className="flex-none">
            <button
              onClick={() => navigate("/courses")}
              style={{ backgroundColor: "#D20639" }}
              className="btn btn-primary w-full lg:w-auto"
            >
              Explore Courses
            </button>
          </div>
        </div>
        <div className="imge-box absolute xl:right-[-260px]  hidden xl:block   bottom-0  ">
          <img src={ManOne} alt="manOne" />
        </div>
      </div>
    </section>
  );
};

export default Banner;
